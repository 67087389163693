<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />
      <HomeBanner />
      <NavMenu />
      <SearchPage /> 
      <SearchBPI @bpi-number="handleInput"/>
      <div v-if="BPINumber">
        <ImageDetails :imagedata="imageData" :inscription="imageInscription" :producerinfo="producerInfo" :producerplace="producerPlace" :producerschools="producerSchools" :imagesubjects="imageSubjects" :biblioref="biblioRef" :imageassocnames="imageAssocNames" :imageassoctitles="imageAssocTitles" :imagedimensions="imageDimensions" :imagelocation="imageLocation"/>
      </div>
     
      <SimpleFooter />
    </div>
  </template>
    
<script>

const path = process.env.VUE_APP_ROOT_API;

import HomeBanner from '../components/HomeBanner.vue';
import NavMenu from '../components/NavMenu.vue';
import SearchPage from '../components/SearchPage.vue';
import SimpleFooter from '../components/SimpleFooter.vue';
import HomeLink from '../components/HomeLink.vue';
import SearchBPI from '../components/SearchBPI.vue';
import ImageDetails from '../components/ImageDetails.vue'

import axios from 'axios'

export default {
  components: { 
    HomeLink,
    HomeBanner, 
    NavMenu,
    SearchPage,
    ImageDetails,
    SearchBPI,
    SimpleFooter
  },
  data() {
      return { 
          bpiList : {},
          imageData : {},
          producerInfo : {},
          producerSchools : {},
          imageSubjects : {},
          biblioRef : {},
          imageAssocTitles : {},
          imageAssocNames : {},
          imageLocation : {},
          producerPlace : '',
          imageLabel : '',
          imageOtherLabel : '',
          imageTitle : '',
          imageComments : '',
          imageInscription : '',
          imageDimensions : '',
          imageRef : '',
          BPINumber : '',
          jpgNumber : ''
      }
  },
  methods: {
    // TODO move to pinia actions
        async getImageDetails(bpi_number) {
          // validate bpi_number value
          if(this.validate(bpi_number)){
             // use axios to call a stored procedure in the API, passing the BPI_catKey as id
            axios
              .get(path + "image_details/" + bpi_number)
              .then((response) => {
                if(response.data.recordset[0] === undefined || response.data.recordset[0].length === 0){
                  console.log('No image data');
                } 
                else {
                  this.imageLabel = response.data.recordset[0].Label !== undefined ? response.data.recordset[0].Label : "";
                  this.imageOtherLabel = response.data.recordset[0].OtherLabel !== undefined  ? response.data.recordset[0].OtherLabel : "";
                  this.imageTitle = response.data.recordset[0].Title !== undefined  ? response.data.recordset[0].Title : "";
                  this.imageDescription = response.data.recordset[0].Description !== undefined ? response.data.recordset[0].Description : "Description unavailable";
                  this.imageComments =  response.data.recordset[0].Comments !== undefined ? response.data.recordset[0].Comments : "Comments unavailable";
                  this.jpgNumber = response.data.recordset[0].Name  !== undefined ? response.data.recordset[0].Name : "Image unavailable";
                  this.collection = response.data.recordset[0].Collection  !== undefined ? response.data.recordset[0].Collection : "";
                  this.museumNumber = response.data.recordset[0].MuseumNumber  !== undefined ? response.data.recordset[0].MuseumNumber : "";
              
                  // filter out html tags
                  this.imageDescription = this.filterHTML(this.imageDescription);
                  this.imageComments = this.filterHTML(this.imageComments);

                  // set the title to imageLabel as a default
                  let title = this.imageLabel;

                  // if main title field is blank try to use another title type field
                  if((title == '' || title == null) && (this.imageOtherLabel == '' || this.imageOtherLabel == null)){
                    title = this.imageTitle;
                  }
                  else if ((title == '' || title == null) && (this.imageTitle == '' || this.imageTitle == null)) {
                    title = this.imageOtherLabel;
                  }

                  this.imageData = {
                    bpiNumber : bpi_number,
                    title : title,
                    description : this.imageDescription,
                    comments : this.imageComments,
                    jpgNum : this.jpgNumber,
                    collection : this.collection,
                    museumNumber : this.museumNumber
                  }
                }  
              })
              .catch(function (err){
              console.log(err);
              })
          }
         
        }, 
        async getImageInscription(bpi_number) {
          this.inscription = '';
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_inscription/" + bpi_number)
            .then((response) => {
              if(response.data.recordset[0] === undefined || response.data.recordset[0].length === 0){
                console.log('No inscription');
              } 
              else {
                this.imageInscription = response.data.recordset[0].mus_inscription_content  !== undefined ? response.data.recordset[0].mus_inscription_content : "Inscription unavailable";
                this.imageInscription = this.filterHTML(this.imageInscription);
              }
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageProducers(bpi_number) {
          this.producerInfo = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_producers/" + bpi_number)
            .then((response) => {
              if(response.data.recordset[0] === undefined || response.data.recordset[0].length === 0){
                console.log('No producer data');
              } 
              else {
                for(let i = 0; i < response.data.recordset.length; i++) {
                  let productionDesc = response.data.recordset[i].al_production_person_assDesc !== undefined ? response.data.recordset[i].al_production_person_assDesc : "No data available";
                  let productionPerson = response.data.recordset[i].mus_object_production_person !== undefined ? response.data.recordset[i].mus_object_production_person : "No data available";
                  let prodObj = {
                    'id' : i,
                    'description' :  productionDesc,
                    'person' : productionPerson
                  }
                  this.producerInfo[i] = Object.assign(prodObj); 
                }
              }  
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageSchools(bpi_number) {
          this.producerSchools = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_schools/" + bpi_number)
            .then((response) => {
              if(response.data.recordset === undefined || response.data.recordset.length === 0){
                console.log('No schools data');
              } 
              else {
                for(let i = 0; i < response.data.recordset.length; i++) {
                  let productionSchool = response.data.recordset[i].bm_school !== undefined ? response.data.recordset[i].bm_school: "No data available";
                  let prodObj = {
                    'id' : i,
                    'school' :  productionSchool 
                  }
                  this.producerSchools[i] = Object.assign(prodObj);
                }
              }  
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageSubjects(bpi_number) {
          this.imageSubjects = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_subjects/" + bpi_number)
            .then((response) => {
              if(response.data.recordset === undefined || response.data.recordset.length === 0){
                console.log('No subject data');
              } 
              else {
                for(let i = 0; i < response.data.recordset.length; i++) {
                  let imageSubjectHeading = response.data.recordset[i].heading !== undefined ? response.data.recordset[i].heading: "No data available";
                  let prodObj = {
                    'id' : i,
                    'subject' :  imageSubjectHeading 
                  }
                  this.imageSubjects[i]= Object.assign(prodObj);
                }
              }  
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageProductionPlace(bpi_number) {
          this.producerPlace = '';
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_production_place/" + bpi_number)
            .then((response) => {
              if(response.data.recordset[0] === undefined || response.data.recordset[0].length === 0){
                console.log('No place data');
              } 
              else { 
                this.producerPlace = response.data.recordset[0].mus_auth_place_mus_auth_thes_ancestry !== undefined ? response.data.recordset[0].mus_auth_place_mus_auth_thes_ancestry: "No place data";
              }  
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getBiblioRef(bpi_number) {
          this.biblioRef = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "biblio_ref/" + bpi_number)
            .then((response) => {
              if(response.data.recordset === undefined || response.data.recordset.length === 0){
                console.log('No bibliographic references');
              } 
              else {
                for(let i = 0; i < response.data.recordset.length; i++) {
                  let reference = response.data.recordset[i].bm_bib_xref !== undefined ? response.data.recordset[i].bm_bib_xref: "No data available";
                  let pages = response.data.recordset[i].bm_bib_spec !== undefined ? response.data.recordset[i].bm_bib_spec: "No data available";
                  let description = response.data.recordset[i].description !== undefined ? response.data.recordset[i].description: "No data available";
                  let prodObj = {
                    'id' : i,
                    'ref' :  reference,
                    'pages' : pages,
                    'desc' : description 
                  }
                  this.biblioRef[i]= Object.assign(prodObj);
                }
              }  
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageAssocNames(bpi_number) {
          this.imageAssocNames = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_assoc_names/" + bpi_number)
            .then((response) => {
              if(response.data.recordset === undefined || response.data.recordset.length === 0){
                console.log('No associated name data');
              } 
              else {
              for(let i = 0; i < response.data.recordset.length; i++) {
                  let name = response.data.recordset[i].bm_as_name !== undefined ? response.data.recordset[i].bm_as_name: "No data available";
                  let desc = response.data.recordset[i].al_as_name_assDesc !== undefined ? response.data.recordset[i].al_as_name_assDesc: "No data available";
                  let assocObj = {
                    'id' : i,
                    'name' :  name,
                    'desc' : desc
                  }
                  let match = false;

                  // always assign the first result
                  if(Object.keys(this.imageAssocNames).length === 0){
                      // assign value
                      this.imageAssocNames[i] = Object.assign(assocObj); 
                  }
                  else {
                    // check for duplicate names and descriptions
                    let itemArray = Object.values(this.imageAssocNames);
                    itemArray.forEach(item => {
                        if(assocObj.name === item.name && assocObj.desc === item.desc ){
                          console.log('we have a matching value');
                          match = true;
                        }    
                    })
                    if(!match)
                    {
                      this.imageAssocNames[i] = Object.assign(assocObj); 
                    }
                  }
              }
            }      
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageAssocTitles(bpi_number) {
          this.imageAssocTitles = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_assoc_titles/" + bpi_number)
            .then((response) => {
              if(response.data.recordset === undefined || response.data.recordset.length === 0){
                console.log('No associated title data');
              } 
              else {
              for(let i = 0; i < response.data.recordset.length; i++) {
                  let title = response.data.recordset[i].bm_alias_as_title !== undefined ? response.data.recordset[i].bm_alias_as_title: "No data available";
                  let prodObj = {
                    'id' : i,
                    'title' : title
                  }
                  this.imageAssocTitles[i]= Object.assign(prodObj);
                }
              }
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        async getImageDimensions(bpi_number) {
          this.imageDimensions = '';
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_dimensions/" + bpi_number)
            .then((response) => {
              if(response.data.recordset[0] === undefined || response.data.recordset[0].length === 0){
                console.log('No dimension data');
              } 
              else { 
                let height =  response.data.recordset[0].mus_dimension + ' ' + response.data.recordset[0].mus_dimension_value + response.data.recordset[0].mus_dimension_measurement_unit
                let width =  response.data.recordset[0].mus_dimension + ' ' + response.data.recordset[0].mus_dimension_value + response.data.recordset[0].mus_dimension_measurement_unit;
                this.imageDimensions = height + ' ' + width;
              }  
            })
            .catch(function (err){ 
            console.log(err);
            })
          }
        },
        async getImageLocation(bpi_number) {
          this.imageLocation = {};
          // use axios to call a stored procedure in the API, passing the BPI_catKey as id
          if(this.validate(bpi_number)){
          axios
            .get(path + "image_location/" + bpi_number)
            .then((response) => {
              if(response.data.recordset === undefined || response.data.recordset.length === 0){
                console.log('No location data');
              } 
              else { 
                let collection = response.data.recordset[0].collection !== undefined ? response.data.recordset[0].collection: "No data available";
                let bmRefNo =  response.data.recordset[0].bm_calc_reg_no_expr !== undefined ? response.data.recordset[0].bm_calc_reg_no_expr: "No data available";
                let note = response.data.recordset[0].mus_acquisition_note !== undefined ? response.data.recordset[0].mus_acquisition_note: "No data available";

                this.imageLocation = {
                  collection : collection,
                  ref : bmRefNo,
                  note : note
                }              
                }  
            })
            .catch(function (err){
            console.log(err);
            })
          }
        },
        filterHTML(str) {
          if ((str===null) || (str===''))
              return '';
          else
              str = str.toString();
              
          // Regular expression to identify HTML tags in
          // the input string. Replacing the identified
          // HTML tag with a null string.
          return str.replace( /(<([^>]+)>)/ig, '');
        },
        validate(bpi_number){
           // check for null etc
           if(bpi_number !== 0 && bpi_number !== null && bpi_number !== undefined) {
              return true;
           }
           return false;
        },
        handleInput(data) {
          // object emitted from the child component
          this.BPINumber = JSON.stringify(data);
          // console.log('BPI number is ', this.BPINumber);
          // call the method passing number as param
          this.getImageDetails(this.BPINumber);
          this.getImageInscription(this.BPINumber);
          this.getImageProducers(this.BPINumber);
          this.getImageSchools(this.BPINumber);
          this.getImageSubjects(this.BPINumber);
          this.getImageProductionPlace(this.BPINumber);
          this.getBiblioRef(this.BPINumber);
          this.getImageDimensions(this.BPINumber);
          this.getImageAssocNames(this.BPINumber);
          this.getImageAssocTitles(this.BPINumber);
          this.getImageLocation(this.BPINumber);
      
        }
    },
}
</script>